import { graphql } from "gatsby"
import React from "react"
import PaymentStatus from "../../containers/PaymentStatus"

const PaymentStatusPage = ({ params }) => {
  const { paymentId } = params

  return <PaymentStatus paymentId={paymentId} />
}

export default PaymentStatusPage

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {
        ns: { in: ["common", "payment_success"] }
        language: { eq: $language }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
