import { CheckCircleIcon } from "@chakra-ui/icons"
import {
  Box,
  Container,
  Flex,
  Heading,
  Spinner,
  Text,
  Link,
} from "@chakra-ui/react"
import { useQuery } from "@tanstack/react-query"
import { AxiosError } from "axios"
import { navigate } from "gatsby"
import { Link as RouteLink } from "gatsby"
import React from "react"
import { Trans, useTranslation } from "react-i18next"

import api from "../api"

import Layout from "../components/Layout"
import SEO from "../components/SEO"
import { isValidPaymentId } from "../utils"

interface PaymentStatus {
  paymentId: string
}

const PaymentStatus = ({ paymentId }) => {
  const { t } = useTranslation()
  const { status, data, isFetched } = useQuery(
    ["payment-status", paymentId],
    async () => {
      if (!isValidPaymentId(paymentId)) {
        navigate("/404")
        return null
      }
      const res = await api.get(`/payment_intents/${paymentId}/status`)
      return res.data
    },
    {
      refetchInterval: 5 * 1000,
      onError: (err: any) => {
        if (err instanceof AxiosError && err.response?.status === 404) {
          navigate("/404")
        }
      },
    }
  )

  const isSuccessStripe = data?.transferStatus === "succeeded"
  const isSuccessFortePay = data?.transferStatus === "Approved"
  const isSuccess = isSuccessStripe || isSuccessFortePay

  if (!isFetched) {
    return (
      <Layout>
        <Container textAlign="center" py="32">
          <Flex my="28" justifyContent="center" alignItems="center">
            <Spinner size="md" />
          </Flex>
        </Container>
      </Layout>
    )
  }

  return (
    <Layout>
      <SEO title="Payment Status" />
      <Container textAlign="center" py="32">
        <Box my="16">
          <Flex justifyContent="center">
            <Heading textAlign="center" w="50%">
              {t("title")}
            </Heading>
          </Flex>
          <Text
            textAlign="center"
            my="10"
            color="gray.400"
            maxW="350px"
            mx="auto"
          >
            {t("description")}
          </Text>

          <Flex
            justifyContent="center"
            flexDirection="column"
            mx="auto"
            alignItems="center"
          >
            <Text fontWeight="medium" fontSize="sm" color="gray.500">
              {t("nft_transfer_label")}
            </Text>
            <Flex mx="2" my="4" fontSize="lg" alignItems="center">
              {isSuccess ? (
                <CheckCircleIcon h={5} w={5} color="green.500" />
              ) : (
                <Spinner size="sm" />
              )}
              <Text mx="2">{isSuccess ? "Success" : "Transferring..."}</Text>
            </Flex>
            {isSuccess && (
              <Text fontWeight="medium" fontSize="sm" color="gray.400">
                <Trans
                  i18nKey="view_collection_message"
                  components={{
                    a: (
                      <Link
                        as={RouteLink}
                        to={`/nft-collection/${data.walletAddress}`}
                        variant="nav"
                      />
                    ),
                  }}
                ></Trans>
              </Text>
            )}
          </Flex>
        </Box>
      </Container>
    </Layout>
  )
}

export default PaymentStatus
